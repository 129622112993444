import React, {useState} from 'react';
import Register from './Register';
import Login from './Login';
import './Auth.scss';

export default function Auth(props) {
    const {setShow, setTitleModal} = props;
    const [showLogin, setShowLogin] = useState(true);
    const showLoginForm = () => {setTitleModal('Inicia sesión'); setShowLogin(true);};
    const showRegisterForm = () => {setTitleModal('Crear cuenta'); setShowLogin(false); };
    return showLogin ? 
    <Login showRegisterForm={showRegisterForm} setShow={setShow}/> : 
    <Register showLoginForm={showLoginForm}  />
}
