import React from 'react';
import {useParams} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import './BannerPerfil.scss';

const BannerPerfil = () => {

    const {lugarname} = useParams();
    return (
        <div className='container_banner_perfil' id='HomeLugar'>
             <Container className='line_green'></Container>
        <Container className='container_data_banner'>
            <h1 className='logo'>infla<span>park</span></h1>
          <h1 className='estado'><span>{lugarname}</span></h1>
        </Container>
        <Container className='line_purple'></Container> 
        </div>
    )
}

export default BannerPerfil;