//Obtener paquetes del lugar
export function getPaquetesApi(id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/package/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}



//Agrega decripciones a cada paquete
export function createDescripcionApi(data, token, id, name) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/package/descripcion/${id}`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.addPaquete) {
                return { ok: true, message: `Nueva descripción agregada a ${name}` };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

export function deleteDescripcionApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/package/descripcion/${id}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.descripcion) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Agrega decripciones a cada paquete
export function createPackageApi(data, token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/package/${id}`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.paquete) {
                return { ok: true, message: 'Se agrego un nuevo paquete' };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}


export function deleteDePaqueteApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/package/${id}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.paquete) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}


//Actualiza los datos de la usuario
export function updatePaquetesDataApi(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/package/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.paquete) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Obtener datos del paquete
export function getPaqueteData(id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/package/lugar/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}