import React, {useState, useEffect} from 'react';
import './PedidosUser.scss';
import {Container, Table} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {getEventosUser} from '../../api/eventos'; 
import {ReactComponent as Ver} from "../../assets/svg/eye-solid.svg";
import Nota from '../../assets/png/nota.png';
import NoItem from '../../Components/NoItem';
import Modal from '../../Components/ModalLg';
import VisualizarEventoUser from '../../Components/VisualizarEventoUser';

export default function PedidosUser() {
    const {auth} = useAuth();
    const [reload, setReload] = useState(false);
    const [eventos, setEventos] = useState(undefined);
const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");  
    const [modalContent, setModalContent] = useState(null);
    
        //Llama todos los usuario  
useEffect(() => {
    (async () =>{
      const response = await getEventosUser(auth.token, auth.id);
      setEventos(response.eventoData);
      setReload(false)
    })()
  }, [auth, reload]);
  return (
    <div className='eventos_user_container'>
   <Container fluid className="users_section_data">
   {eventos === undefined || eventos === '' || eventos?.length === 0 ?
    <div className="texto">
    <h1>Aun no tienes eventos</h1>
  </div>
    
    :
    <Table responsive>
    <thead>
      <tr>
        <th>Evento</th>
        <th>Paquete</th>
        <th>Fecha</th>
        <th>Abonado</th>
        <th>Adeudo</th>
        <th>Total</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
          {
            eventos?.map((item =>{
              return(
              <tr key={item.uid}>
              <td>{item.name ?? ''}</td>
              <td><p>{item.paquete ?? ''}</p></td>
              <td>{
              Intl.DateTimeFormat('es-MX', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(Date.parse(item.fecha))  
               ?? ''}</td>
              <td><Abonos abonos={item.abonos}/></td>
              <td><Adeudo abonos={item.abonos} total={item.precioTotal}/></td>
              <td className='total'>${item.precioTotal ?
              Intl.NumberFormat('es-MX').format(item.precioTotal)
               : 0}</td>
              <td className='options_user_admin'>
                <div className="visualizar">
                 <Visualizar
                 data={item} 
                 setShow={setShow} 
                 setModalTitle={setModalTitle} 
                 setModalContent={setModalContent} 
                 />
                </div>
                 </td>
              </tr>
            )}))
          }
        </tbody>
    </Table>
    }
   </Container>
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    /> 
    </div>
  )
}  




const Abonos =({abonos}) =>{

      
    const sumaabonos = abonos.reduce((prev, next) => parseFloat(prev) + parseFloat(next.abono), 0);
  

return(
    <span className='abonos'>$
     {Intl.NumberFormat('es-MX').format(sumaabonos)   }
        </span>
)

}

const Adeudo =({abonos, total}) =>{

    
  const sumaabonos = abonos.reduce((prev, next) => parseFloat(prev) + parseFloat(next.abono), 0);

  const adeudo = total - sumaabonos;
return(
  <span className='adeudo'>$
   {Intl.NumberFormat('es-MX').format(adeudo)   }
      </span>
)

}

const Visualizar = ({data, setShow, setModalTitle, setModalContent}) => {
    
    const editUser = data =>{
        setShow(true);
        setModalTitle(`${data?.name}`)
        setModalContent(<VisualizarEventoUser  
        eventos={data}
        isUser={true}
          />)
      }
    return (
      <>
      <Ver onClick={()=>editUser(data)}/>
      <p>Consultar</p>
      </>
      
    );
  }