import React, {useState, useEffect} from 'react';
import {Form, Button, Col, Row} from 'react-bootstrap';
import './EditUserForm.scss';
import {updatePaquetesDataApi} from '../../api/paquetes';
import {toast} from 'react-toastify';

//Crear formulario de edición de usuario y también crear ruta, para actualizar información del usuario
function EditUserForm({userdata ,setReload,setShow, token}) {

  const [updateUser, setUpdateUser] = useState({});
  useEffect(() => {
    setUpdateUser({
     name:userdata.name,
     descuento:userdata.descuento,
     color:userdata.color,
     colorTexto:userdata.colorTexto,
     basico:userdata.basico,
     grande:userdata.grande,
     descripcion:userdata.descripcion,
     duracion:userdata.duracion
    })
   }, [userdata])



     /* Se actualizan los datos del puesto */
  const update = e =>{
    e.preventDefault();
    
/* 
    if(!updateDataUser.phone.length !==10){
      toast.error("Número de teléfono debe de contener 10 dígitos.")
      return;
    } */

  /*   if(!updateDataUser.name || !updateDataUser.email){
      toast.error("Nombre y e-mail son obligatorios.")
      return;
    } */
    
  
    updatePaquetesDataApi(token, updateUser, userdata._id).then(res =>{
      
      if(res.ok === true){
        toast.success(res.message);
        setShow(false);
        setReload(true);        
   }else{
      toast.error(res.message)
   }
      })
      
    
    }

    
  return (
    <Form onSubmit={update}>
      <Row>
      <Col md={12} sm={12} xl={12}>
      <Form.Group className="mb-3" controlId="formBasicEmail" id='label_switch'>
      <Form.Control 
      type="text" 
      placeholder="Nombre del paquete"
      value={updateUser.name}
      onChange={ e => setUpdateUser({...updateUser, name: e.target.value})}
      />
      </Form.Group>
        </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="precio 50 a 100 personas"
    value={updateUser.basico}
    onChange={e => setUpdateUser({...updateUser, basico: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="precio 101 a 150 personas"
    value={updateUser.grande}
    onChange={e => setUpdateUser({...updateUser, grande: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Tiempo de duración"
    name="duracion"
    value={updateUser.duracion}
    onChange={e => setUpdateUser({...updateUser, duracion: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="Descuento(opcional)"
    name="descuento"
    value={updateUser.descuento}
    onChange={e => setUpdateUser({...updateUser, descuento: e.target.value})}
    />
    </Form.Group>
      </Col>
        <Col md={6} sm={12} xl={6}>
        <Form.Group className="mb-3">
    <Form.Select
    placeholder="Selecciona un color de fondo"
    onChange={e => setUpdateUser({...updateUser, color: e.target.value})}
    value={updateUser.color}
    > 
      <option value="#ffffff">Color de fondo</option>
      <option value="#ff6900" id='naranja'>naranja </option>
      <option value="#fcb900" id='amarillo'>amarillo </option> 
      <option value="#00d084" id='verde1'>verde1 </option> 
      <option value="#69C528" id='verde2'>verde2 </option> 
      <option value="#0693e3" id='azul1'>azul1 </option> 
      <option value="#0693e3" id='azul2'>azul2 </option> 
      <option value="#EB144C" id='rojo'>rojo </option> 
      <option value="#F78DA7" id='rosa'>rosa </option> 
      <option value="#7f00b2" id='morado'>morado </option> 
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
        <Form.Group className="mb-3">
    <Form.Select
    placeholder="Selecciona un color de texto"
    onChange={e => setUpdateUser({...updateUser, colorTexto: e.target.value})}
    value={updateUser.colorTexto}
    >
      <option value="#6c0e8a">Color de texto</option>
      <option value="#ffffff" id='blanco'>blanco</option>
      <option value="#000000" id='negro'>negro</option> 
      <option value="#f0f2f5" id='gris'>gris</option> 
      <option value="#a2a2a2" id='gris2'>gris2</option> 
      <option value="#7f00b2" id='morado'>morado </option> 
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12} className='body'>
              <Form.Control as="textarea" 
              rows={4}
              name="descripcion"
              placeholder="Escribe una descripción del paquete."
              value={updateUser.descripcion}
              onChange={e => setUpdateUser({...updateUser, descripcion: e.target.value})}
              />
              </Col>
      </Row>
    <Button variant="primary" type="submit">
      Actualizar
    </Button>
  </Form>
  );
}

export default EditUserForm;
