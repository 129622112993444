import React,{useState, useEffect} from 'react';
import {Row, Col, Container, Form, Spinner} from 'react-bootstrap';
import {ReactComponent as Delete} from "../../../assets/svg/times-solid.svg";
import './ContenidoPaquete.scss';
import {getPaquetesApi} from '../../../api/paquetes';
import NoImage from '../../../assets/png/caja.png';
import NoItem from '../../NoItem';

export default function ContenidoPaquete({lugar, inputPaquete, inputs, setInputs}) {
  const [paquetes, setPaquetes] = useState(undefined);
 
  useEffect(() => {
    (async () =>{
      const response = await getPaquetesApi(lugar);
      setPaquetes(response.paquetes);
    })()
  }, [lugar]);
  return (
    <div className='Select_paquete_data_admin'>
    { 
    !paquetes?
       <div className='spnner_container'>
       <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    <span >Esperé...</span>
       </div>
       :
       (inputPaquete === undefined || inputPaquete === "") ?
       <NoItem
       imagen={NoImage}
       texto={'Debes seleccionar un paquete'}
       />
       
       :
       <Row>
         {
       paquetes?.filter(item =>{
        if(item.name === inputPaquete ){
          
          return item;
        
        }
        
      }).map((item =>{
         
         return(
        <Col md={12} sm={12} xl={12} className='container_paquetes_body' /* style={{color:item?.color}} */>
          
            <div className="funciones_paquetes">
            <h4>{item?.name}</h4>
            </div>
           <hr />
           <h6>Incluye:</h6>
          <Info 
          descripcion={item?.descripciones} 
          />
          <hr/>
           <h6>Complementos:</h6>
           <Row>
          <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Pastel"
    name="pastel"
    value={inputs.pastel}
    onChange={e => setInputs({...inputs, pastel: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Refrescos"
    name="refrescos"
    value={inputs.refrescos}
    onChange={e => setInputs({...inputs, refrescos: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Agua(s)"
    name="agua"
    value={inputs.agua}
    onChange={e => setInputs({...inputs, agua: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Piñata"
    name="piñata"
    value={inputs.piñata}
    onChange={e => setInputs({...inputs, piñata: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Show"
    name="show"
    value={inputs.show}
    onChange={e => setInputs({...inputs, show: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="Dulceros"
    name="dulceros"
    value={inputs.dulceros}
    onChange={e => setInputs({...inputs, dulceros: e.target.value})}
    />
    </Form.Group>
      </Col>
      </Row>
      <hr/>
           <h6>Guisado(s):</h6>
           <Row>
          <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Guisado"
    name="guisado"
    value={inputs.guisado}
    onChange={e => setInputs({...inputs, guisado: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Guisado"
    name="guisado1"
    value={inputs.guisado1}
    onChange={e => setInputs({...inputs, guisado1: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Guisado"
    name="guisado2"
    value={inputs.guisado2}
    onChange={e => setInputs({...inputs, guisado2: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Guisado"
    name="guisado3"
    value={inputs.guisado3}
    onChange={e => setInputs({...inputs, guisado3: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Guisado"
    name="guisado4"
    value={inputs.guisado4}
    onChange={e => setInputs({...inputs, guisado4: e.target.value})}
    />
    </Form.Group>
      </Col>
      </Row>
      <Row>
      <Col md={12} sm={12} xl={12}>
      <Form.Group  controlId="ControlTextarea1">
        <Form.Label>
        Menú infantil:
        </Form.Label>
        <Form.Control 
         as="textarea"
         rows={3}
         placeholder="Agrega el menú para los niños"
         value={inputs.infantil}
         onChange={e => setInputs({...inputs, infantil: e.target.value})}
         />
        </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row>
      <Col md={12} sm={12} xl={12}>
        <Form.Group  controlId="ControlTextarea1">
        <Form.Label>
        Comentarios:
        </Form.Label>
        <Form.Control 
         as="textarea"
         rows={5}
         placeholder="Puedes agregar datos adicionales del paquete(opcional)"
         value={inputs.comentarios}
         onChange={e => setInputs({...inputs, comentarios: e.target.value})}
         />
        </Form.Group>
        </Col>
        </Row>
         {/*  <button onClick={()=>addPaquete(item)}><Add/> Agregar servicio</button> */}
          
        </Col>
       )}))
      }
      
       </Row>
        
   
}

    </div>
  )
}

const Info =({descripcion}) =>{ 
return(
  descripcion?.map((item) =>{
    return(

    <Row>
      <Col md={12} sm={12} xl={12}>
        <li style={{color: "white"}}>
          {item?.descripcion}
        </li>
        
    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>
      Articulo
    <Delete/>
    </Form.Label>
    <Form.Control 
    key={item._id}
    type="text" 
    placeholder="Nombre del evento"
    name="descripcion"
    value={item?.descripcion}
    onChange={e => cambioItem(index, e)}
    />
    </Form.Group> */}
      </Col>
    </Row>
  )})
)
}