import React,{useState} from 'react'
import {Container, Button} from 'react-bootstrap';
import {activarCuenta} from '../../api/user';
import Bot from '../../assets/png/Infla.png';
import {useParams} from 'react-router-dom'; 
import {toast} from 'react-toastify';
import './Activar.scss';
 
const Activar = () => {
    const {token} = useParams();

    const [values,setValues]= useState({
        active:true
    });
  
    const {active} = values;
    
  
  
     const activate =async e => {
      e.preventDefault();
      
    const res = await activarCuenta(token, active)
        
        if(res.ok){
          
           setValues({...values, active:true});
           toast.success(res.msg)
           window.location.href = "/";
     }else{
      
        toast.error(res.msg);
        window.location.href = "/";
     }
  
  }
    
    return (
        <Container className='container_active'>
            <img src={Bot} alt="" />
            <div className="box">
             <h7>Presiona el botón para activar tu cuenta</h7>
             <Button onClick={activate}>Activar</Button>
            </div>
        </Container>
    );
}

export default Activar;
