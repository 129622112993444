import React, {useEffect} from 'react';
import {Container} from 'react-bootstrap';
import './Map.scss';

export default function Map({direccion,mapaURL}) {
    return (
        <>
        {
         (mapaURL === undefined || mapaURL === null || mapaURL === '') ?
         
         '' :

         <Container fluid className='container__map' id='Ubicacion'>
            <Container>
            <p><span>Nos encontramos en: </span>{direccion}.</p>    
            </Container>
           <iframe className='myMap'  src={mapaURL} width="100%" height="80%" frameborder="0" style={{border:0,}} allowfullscreen="" loading="lazy"></iframe>
        </Container>   
        
        }
        </>
    )
}
