import React, {useState, useEffect }from 'react';
import {Container, Table, Form} from 'react-bootstrap';
import Activo from '../../../Components/Activo';
import Inactivo from '../../../Components/Inactivo';
import Modal from '../../../Components/ModalNormal';
import {ReactComponent as Edit} from "../../../assets/svg/admin/user-edit.svg";
import {ReactComponent as Delete} from "../../../assets/svg/admin/trash.svg";
import EditUserForm from '../../../Components/EditUserForm';
import DeleteUserData from '../../../Components/DeleteUser';
import useAuth from '../../../hooks/useAuth';
import {getUsers, getLUserDataApi, deleteUsuarioApi} from '../../../api/user';
import {getLugaresDataApi} from '../../../api/lugares';

export default function Users() {
  const [user, setUser] = useState(undefined);
  const [usersData, setUsersData] = useState(undefined); 
  const [lugares, setLugares] = useState(undefined);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const {auth} = useAuth();
  

  //llama datos del usuario autenticado
  useEffect(() => {
    (async () =>{
      const response = await getLUserDataApi(auth.token, auth.id);
      setUser(response.userData);
    })()
  }, [auth]);

  //llama datos de todos los usuarios
  useEffect(() => {
    (async () =>{
      const response = await getUsers(auth.token);
      setUsersData(response.users);
      setReload(false)
    })()
  }, [auth, reload]);

  //llama datos de los lugares existentes
  useEffect(() => {
    (async () =>{
      const response = await getLugaresDataApi();
      setLugares(response.lugar);
    })()
  }, [auth]);
  
  return (
  <div fluid className='users_section'>
   <div fluid className='users_section_nav'>
     <Container >
<h5>Administradores</h5>
   <div className="search_bar">
   <Form.Control type="text" 
   placeholder="Buscar..." 
   onChange={(e)=>{
    setSearch(e.target.value);
  }} 
   />
   </div>
     </Container>
   </div>
   <Container fluid className="users_section_data">
    <Table responsive>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Apellido</th>
        <th>E-mail</th>
        <th>Teléfono</th>
        <th>Activo</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
          {
            usersData?.filter(item =>{
              if(user?.role === "admin"){
                return item.role ==="admin"
              }else{
               if(item.role ==="admin" || item.role ==="super_admin"){
                if(search ===''){
                return item;
              }else if(
                (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
                (item.lastname && item.lastname.toLowerCase().includes(search.toLowerCase())) ||
                (item.email && item.email.toLowerCase().includes(search.toLowerCase()))
              ){
             
                return item
              }
              } 
              }
              
              
            }).map((item =>{
              return(
              <tr key={item.uid}>
              <td className='options_user_name'>{item.name ?? ''}</td>
              <td><p>{item.lastname ?? ''}</p></td>
              <td>{item.email ?? ''}</td>
              <td>{item.phone ?? ''}</td>
              <td>{item.active == true ? <Activo texto={'Activo'}/> : <Inactivo texto={'Inactivo'}/>}</td>
              <td className='options_user_admin'>
                <div className="edit">
                  <EditUser 
                  userdata={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  auth={auth.id}
                  lugares={lugares}
                  admin={user}
                  />
                </div>
                <div className="delete">
                  {

                    
                    user?.role === 'super_admin' ?
                    <DeleteUser 
                  userdata={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  deleteUsuarioApi={deleteUsuarioApi}
                  />
                  : ''
                  }
                
                </div>
                 </td>
              </tr>
            )}))
          }
        </tbody>
    </Table>
   </Container>
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    /> 
  </div>
  );
}


const EditUser = ({userdata, setShow, setModalTitle, setModalContent, setReload, token, auth, lugares, admin}) => {
  
  const editUser = userdata =>{
    setShow(true);
    setModalTitle(`Editanto a ${userdata.name}`)
    setModalContent(<EditUserForm userdata={userdata} setReload={setReload} setShow={setShow} token={token} auth={auth} lugares={lugares} admin={admin}/>)
  }
  return (
    <>
    <Edit onClick={()=>editUser(userdata)}/>
    <p>Editar</p> 
    </>
  );
}

const DeleteUser = ({userdata, setShow, setReload, token,  setModalTitle, setModalContent, deleteUsuarioApi}) => {
  
  const editUser = userdata =>{
    setShow(true);
    setModalTitle("")
    setModalContent(<DeleteUserData titulo={userdata.name} setReload={setReload} setShow={setShow} token={token} id={userdata.uid} deleteFunction={deleteUsuarioApi} />)
  }
  return ( 
    <>
    <Delete onClick={()=>editUser(userdata)}/>
    <p>Eliminar</p>
    </>
  );
}



