import React, {useState} from 'react';
import {Container} from 'react-bootstrap';
import Modal from '../ModalNormal';
import Auth from '../Auth';
import useAuth from '../../hooks/useAuth';
import {ReactComponent as User} from "../../assets/svg/user.svg"; 
import {ReactComponent as Open} from "../../assets/svg/bars-solid.svg";

import './Navbar.scss';


export default function Navbar(props) {
  const {user, button, setButton} = props;
  const [data, setData] = useState("opciones_user");
  const [show, setShow] = useState(false);
  const {logout, auth} = useAuth();
  

  const fecha = Date.now()


  const [titleModal, setTitleModal] = useState("Inicia sesión");

  const dataUserMenu = () =>{
    data === 'opciones_user' ? setData('opciones_user mostrar') : setData('opciones_user') 
  }

  const navToggle = () =>{
/*     active === 'navBar' ? setActive('navBar nav__active') : setActive('navBar') */
    button === false ? setButton(true) : setButton(false)
  }
  
  const cerrarSesion = () =>{
    logout();
    setData('opciones_user');
    
  }

    return (
      <>
        <Container fluid className='navbar_top_admin'>
          <Container fluid>
            <div className="date_button">
             <div className="menu_burguer" id="menu_burguer" onClick={navToggle}>
             <Open/> 
            </div> 
            <div className="section_logo">
            <div className="fecha_hoy">
         {Intl.DateTimeFormat('es-MX', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(fecha)}
             </div>
            </div> 
            </div>
         <div className='Contaier_navBar_principal'>
         <div className="datauser">
             <p><span>Hola, </span>{user?.name}</p>
             <span>{(user?.role === 'admin' )? 'Administrador' : (user?.role === 'super_admin') ? 'Master Admin' : '' }</span>
         </div>
        <User/> 
        </div> 
         
          </Container>
        </Container >
        <Modal
        show={show}
        setShow={setShow}
        title={titleModal}
        body={<Auth
        setTitleModal={setTitleModal}
        setShow={setShow}
        />}
        />
        </>
    )
}
