import React,{useMemo, useState, useEffect} from 'react';
import routes from './routes/routes';
import jwt_decode from "jwt-decode";
import AuthContext from './context/AuthContext';
import CartContext from './context/CartContext';
import {Redirect} from 'react-router-dom';
import {setToken, getToken, removeToken} from './api/token';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {getProductsCart, addProductCart, countProductsCart} from './api/cart'
import { ToastContainer, toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


function App() {
  const [auth, setAuth] = useState(undefined);
  const [totalCart, setTotalCart] = useState(0);
  const [reloadUser, setReloadUser] = useState(false);
  const [reloadCart, setReloadCart] = useState(false);

  useEffect(() => {
    setTotalCart(countProductsCart());  
    setReloadCart(false)
  }, [reloadCart, auth]);
  
  useEffect(() => {
    const token = getToken();
    if(token){
      setAuth({
        token,
        id: jwt_decode(token).user?.id
      })
    }else{
      setAuth(null)
    }
    setReloadUser(false);
  }, [reloadUser]);

  const login = (token) =>{
  setToken(token);
    setAuth({
      token,
      id: jwt_decode(token).user?.id
    })
  }
  
  const logout = () =>{
    if(auth){
      removeToken();
      setAuth(null);
      <Redirect to ="/" />
    }
  }

  const addProduct = (product) =>{
    const token = getToken();
    if(token){
      addProductCart(product);
      setReloadCart(true);
    }else{
     toast.warning('Para poder añadir un paquete necesitas iniciar sesión')
    }
  }

  const authData = useMemo(
    () =>({
     auth,
     login,
     logout,
     setReloadUser,
    }),
    [auth]
  );

  const cartData = useMemo(
    () =>({
      productsCart: totalCart,
      addProductCart: (product) => addProduct(product),
      getProductsCart: getProductsCart,
      removeProductCart: () => null,
      removeAllProductsCart: () => null,
    }), [totalCart]
  )

  if(auth === undefined) return null;

  return (
    <AuthContext.Provider value={authData}>
      <CartContext.Provider value={cartData}>
    <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <Router>
      <Switch>
{routes.map((route, index) =>(
  <RouteWithSubRoutes key={index}{...route} />
))}
      </Switch>
    </Router>
    </CartContext.Provider>
    </AuthContext.Provider>
  );
}

function RouteWithSubRoutes(route){
  return (
  <Route 
  path={route.path}
  exact={route.exact}
  render={props => <route.component routes={route.routes}{...props}/>}
  />
  );
}


export default App;
