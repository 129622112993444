import React from 'react';
import {ReactComponent as Address} from "../../assets/svg/map-marker.svg";
import {ReactComponent as Clock} from "../../assets/svg/clock-regular.svg";
import {ReactComponent as Phone} from "../../assets/svg/phone.svg";
import {Container, Col, Row} from 'react-bootstrap';

import './LugaresData.scss';
export default function LugaresData({key, sucursal, direccion, horario, enlace, telefono}) {
 
  return (
    <>
     
      <Container key={key}>
          <Row className='data_componente_lugar'>
          <Col xs={3} md={2} sm={3} xl={2} className='icono'>
          <Address/> 
          </Col>
          <Col xs={9} md={10} sm={9} xl={10} className='datos'>
          <h4>{sucursal}</h4> 
            <p>{direccion}</p>
          </Col>
          </Row>
          <Row className='data_componente_lugar'>
          <Col xs={3} md={2} sm={3} xl={2} className='icono'>
          <Clock/>
          </Col>
          <Col xs={9} md={10} sm={9} xl={10} className='datos'>
          <h4>Horario</h4> 
            <p>{horario}</p>
          </Col>
          </Row>
          <Row className='data_componente_lugar'>
          <Col xs={3} md={2} sm={3} xl={2} className='icono'>
          <Phone/>
          </Col>
          <Col xs={9} md={10} sm={9} xl={10} className='datos'>
          <h4>Teléfono</h4> 
            <p className='telefono'>{telefono}</p>
          </Col>
          </Row>
          <div className="buttons_data_lugares">
          <a href={`/${enlace}`}>Ver perfil</a>
          </div>
      </Container>
      </>
  )
}
