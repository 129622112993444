import React from 'react';
import CarritoCompras from '../../assets/png/carts.png';
import {Container} from 'react-bootstrap';
import NoItem from '../../Components/NoItem';
import './Carrito.scss';

const Carrito = () => {

    return (
        <Container className='container_carrito'>
            <NoItem 
            imagen={CarritoCompras}
            texto={"Tu carrito está vacio"}
            />   
        </Container>
    );
    
}

export default Carrito;
