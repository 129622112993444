import React, {useState, useEffect }from 'react';
import {Container, Button, Form} from 'react-bootstrap';
import {useParams, useHistory} from 'react-router-dom';
import {ReactComponent as Back} from "../../../assets/svg/back.svg";
import {ReactComponent as Next} from "../../../assets/svg/right-solid.svg";
import {getLUserDataApi} from '../../../api/user';
import {crearEvento} from '../../../api/eventos';
import './UserProfile.scss';
import useAuth from '../../../hooks/useAuth';
import ErrorPage from '../../Error404';
import AddEventoAdmin from '../../../Components/StepsForm/AddEventoAdmin';
import AddNameEvent from '../../../Components/StepsForm/AddNameEvent';
import AddPayment from '../../../Components/StepsForm/AddPayment';
import ContenidoPaquete from '../../../Components/StepsForm/ContenidoPaquete';
import {toast} from 'react-toastify';
import confetti from "canvas-confetti";
import moment from 'moment';
import 'moment/locale/es-mx'

export default function UserProfile() {
  const history = useHistory();
  const [user, setUser] = useState(undefined);
  const [adminData, setAdminData] = useState(undefined);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const {auth} = useAuth();
  const params = useParams();
  const date1 = "YYYY-MM-DD";

 /*  const fechaPrueba=  moment(Date.now()).format('LL'); */


  
 /*  utilizar, cuando se vaya a guardar el dato de fecha Date.parse() */
  const [inputs, setInputs] = useState({
    name:"", 
    turno:"", 
    fecha:"",
    paquete:"",
    personas: '',
    comentarios:'',
    pastel:'',
    refrescos:'',
    agua:'',
    piñata:'',
    show:'',
    dulceros:'',
    guisado:'',
    guisado1:'',
    guisado2:'',
    guisado3:'',
    guisado4:'',
    infantil:'',
    descuento:0,
    precio:0,
    precioTotal: 0,
    precioPaquete: 0,
    entrada:'',
    salida: '',
    iva: 0,
});


const [adicionales, setAdicionales] = useState([{
  nombre:'',
  precio:0,
  piezas: 1, 
  total:0
}]);

const sumaPrecios = adicionales.reduce((prev, next) => prev + next.total, 0);

useEffect(() => {
 setTotal(parseFloat(inputs?.precioPaquete) + sumaPrecios);
}, [inputs, sumaPrecios]);




const [abonos, setAbonos] = useState([{
  abono:0,
  metodo:'',
  fechadePago: moment(Date.now()).format(date1),
  recibio:auth?.id
}]);

const abonoData = (index, e)=>{
  const values =[...abonos];
  values[index][e.target.name] = e.target.value;
  setAbonos(values)
}

const cambioData = (index, e)=>{
  const values =[...adicionales];
  values[index][e.target.name] = e.target.value;
  setAdicionales(values)
}

const AddFormProducto = () =>{
  setAdicionales([...adicionales, {
    nombre:'',
    precio:0,
    piezas: 1, 
    total:0 
  }])
}
const RemoveFormProducto = (index) =>{
 const values = [...adicionales];
 values.splice(index, 1);
 setAdicionales(values);
}

  useEffect(() => {
    (async () =>{
      const response = await getLUserDataApi(auth?.token, auth?.id);
      setAdminData(response.userData);
    })()
  }, [auth]);

  useEffect(() => { 
    (async () =>{
      const response = await getLUserDataApi(auth?.token, params?.id);
      setUser(response.userData);
      setReload(false)
    })()
  }, [auth, reload]);
  const FormTitles =["Crear evento", "Paquete solicitado", "Adicionar Extras", "Pago o abono"];
  
  const dataNew = {
    adicionales: adicionales,
    abonos:abonos,
    user: user?.uid,
    name:inputs.name, 
    turno:inputs.turno,
    fecha:inputs.fecha.replace(/-/g,'/'),
    paquete:inputs.paquete,
    personas:inputs.personas,
    comentarios:inputs.comentarios,
    pastel:inputs.pastel,
    refrescos:inputs.refrescos,
    agua:inputs.agua, 
    piñata:inputs.piñata, 
    show:inputs.show,
    dulceros:inputs.dulceros,
    guisado:inputs.guisado,
    guisado1:inputs.guisado1,
    guisado2:inputs.guisado2, 
    guisado3:inputs.guisado3,
    guisado4:inputs.guisado4,
    infantil:inputs.infantil,
    precioTotal:inputs.precioTotal,
    creador: auth?.id,
    precioPaquete: inputs.precioPaquete,
    lugar:adminData?.lugar,
    userName:user?.name,
    email:user?.email,
    descuento: inputs.descuento,
    precio: inputs.precio,
    fechaDeRegistro:new Date(Date.now()).toISOString().substring(0, 10),
    entrada: inputs.entrada,
    salida: inputs.salida,
    iva:inputs.iva
  } 

  
  const sumaabonos = abonos.reduce((prev, next) => prev + next.abono, 0);
     

  const register = async e =>{

    /* if(Date.parse(dataNew.fecha) <= Date.now() ){
      e.preventDefault();
      toast.error("No puedes seleccionar una fecha menor a la del día de hoy.")
      return; 
    } */

    if(dataNew.name === '' || dataNew.fecha=== '' || dataNew.turno === '' || dataNew.paquete === '' || dataNew.precioPaquete === ''){
      e.preventDefault();
      toast.error("Nombre del evento, fecha, horario, paquete y personas, son datos obligatorios. ")
      return; 
    }

    console.log(sumaabonos)
    if(parseFloat(sumaabonos) <= 0){
      e.preventDefault();
      toast.error('Agrega una cantidad minima de abono.')
      return; 
    }

    if(parseFloat(sumaabonos) >  dataNew.precioTotal){
      e.preventDefault();
      toast.error("la suma del abono es mayor que el precio del evento")
      return; 
    }    

    e.preventDefault();  
  
      setLoading(true)


       const res = await crearEvento(dataNew, auth.token);
       if(res.ok ===false){
        toast.error(res.message)
     }else{
        toast.success(res.message);
          setReload(true);   
          setShow(false);
          var duration = 7 * 1000;
          var animationEnd = Date.now() + duration;
          var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
          
          function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
          }
          
          var interval = setInterval(function() {
            var timeLeft = animationEnd - Date.now();
          
            if (timeLeft <= 0) {
              return clearInterval(interval);
            }
          
            var particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
            confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
          }, 250);  
          history.push("/admin/users", { from: "Users" });
            
     }
     setLoading(false);
     e.preventDefault(); 
    
    
  }

  

 
  const PageDisplay = () =>{
    if(page === 0){
      return(
        <AddNameEvent
      lugar={adminData?.lugar}
      inputs={inputs}
      setInputs={setInputs}
      />
      ) 
    }
    else if(page ===1){
      return(
       <ContenidoPaquete
       lugar={adminData?.lugar}
       inputPaquete={inputs?.paquete}
       inputs={inputs}
       setInputs={setInputs}
       /> 
      ) 
    }
    else if(page ===2){
      return(
       <AddEventoAdmin
       adicionales={adicionales}
       cambioData={cambioData}
       setReload={setReload}
       reload={reload}
       AddFormProducto={AddFormProducto}
       RemoveFormProducto={RemoveFormProducto}
       /> 
      ) 
    }
    else if(page ===3){
      return(
       <AddPayment
       loading={loading}
       total={total}
       abonos={abonos}
       abonoData={abonoData}
       register={register}
       inputs={inputs}
       setInputs={setInputs}
       dataNew={dataNew}
       adminData={adminData}
       user={user}
       /> 
      ) 
    }
    else{
      return(
        <ErrorPage/>
      )
    }
  }

  return (
      <div className='container_profile_User'>
      <div  className='container_admin_footer_nav'>
     <Container >
<h5>{user?.name} {user?.lastname} </h5>

     </Container>
   </div>
   <Container className='titulos_paginas'>
     <h1>{FormTitles[page]}</h1>
   </Container>
   <Container className='container_progress'> 
     <div className="progressBar" style={{width: page === 0 ? "25%" : page === 1 ? "50%" : page===2 ? "75%" : "100%", height: "20px" }}>
     <p>{page === 0 ? "25%" : page === 1 ? "50%" : page===2 ? "75%" : "100%"}</p>
     </div>
   </Container>
   <Container>
    <Container className='container_form_pedido'>
    <Form onSubmit={register}>
    {PageDisplay()}   
     </Form>
    </Container>   
   </Container>
   <Container className='buttons_next_back'>
   <Button
     variant="primary" 
     disabled={page == 0}
     onClick={() => {setPage((currPage) => currPage -1);}}  
     className='btn_back'>
      <Back/> 
      <p>Anterior</p> 
       </Button> 
   <Button
     variant="primary" 
     disabled={page == FormTitles.length -1}
     onClick={() => {setPage((currPage) => currPage +1);}}  
     className='btn_extra'>
       
      <p>Siguiente</p> 
      <Next/>
    </Button>  
   </Container>
    

      </div>
      
  )
}

