import React from 'react';
import {Modal} from 'react-bootstrap';
import './Modal.scss';

const ModalNormal = ({show, setShow, title, body}) => {
   
    return (
        <>
           <Modal
            show={show}
            /* size="lg" */
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            className='modal__normal'
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
               {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor:"black"}}>
              {body}
            </Modal.Body>
          </Modal>
        </>
      );
}

export default ModalNormal;
