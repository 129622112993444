import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import { Link} from 'react-router-dom';
import Logo from '../../assets/png/logo.png';
import Modal from '../ModalNormal';
import Auth from '../Auth';
import useAuth from '../../hooks/useAuth';
import {getLUserDataApi} from '../../api/user';
import {ReactComponent as User} from "../../assets/svg/user.svg";

import './Navbar.scss';

 
export default function Navbar() {
  const [data, setData] = useState("opciones_user");
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(undefined);
  const {logout, auth} = useAuth();
  
  useEffect(() => {
    (async () =>{
      const response = await getLUserDataApi(auth.token, auth.id);
      setUser(response.userData);
    })()
  }, [auth]);
   
 
 


  const [titleModal, setTitleModal] = useState("Inicia sesión");

  
  const cerrarSesion = () =>{
    logout();  
    setData('opciones_user');
    
  }

    return (
      <>
        <Container className='navbar_top_user' fluid>
          <Container>
          <Link className="logo" to={"/"} > 
  <img src={Logo} alt="" />
         </Link>
         <div className="icons">
           {auth && user?.role ==="user" ? ( 
             <>
             <a  className='cuenta' onClick={cerrarSesion}>Cerrar Sesión</a>
             </>
           ):
          (<User className='user' onClick={()=> setShow(true)} />)
          } 
           
         </div>
          </Container>
         <div className="line"></div>
        </Container >
        <Modal
        show={show}
        setShow={setShow}
        title={titleModal}
        body={<Auth
        setTitleModal={setTitleModal}
        setShow={setShow}
        />}
        />
        </>
    )
}

