import React, {useState, useEffect} from 'react';
import {Container, Col, Row, Table, Form, Button} from 'react-bootstrap';
import {getFooterDataApi, updateFooterDataApi} from '../../../api/footer';
import useAuth from '../../../hooks/useAuth';
import {toast} from 'react-toastify';
import './Footer.scss';

const Footer = () => {
    const {auth} = useAuth();
    const [reload, setReload] = useState(false);
    const [footer, setFooter] = useState([]);
    const [updateFooter, setUpdateFooter] = useState({});
    useEffect(() => {
        setUpdateFooter((prevFooter) => ({
          activeDescripcion: footer?.activeDescripcion ?? prevFooter.activeDescripcion,
          descripcion: footer?.descripcion ?? prevFooter.descripcion,
          direccion: footer?.direccion ?? prevFooter.direccion,
          activeDireccion: footer?.activeDireccion ?? prevFooter.activeDireccion,
          activeContacto: footer?.activeContacto ?? prevFooter.activeContacto,
          emailContacto: footer?.emailContacto ?? prevFooter.emailContacto,
          emailTrabajo: footer?.emailTrabajo ?? prevFooter.emailTrabajo,
          activeTrabajo: footer?.activeTrabajo ?? prevFooter.activeTrabajo,
          phone1: footer?.phone1 ?? prevFooter.phone1,
          activePhone1: footer?.activePhone1 ?? prevFooter.activePhone1,
          phone2: footer?.phone2 ?? prevFooter.phone2,
          activePhone2: footer?.activePhone2 ?? prevFooter.activePhone2,
          phone3: footer?.phone3 ?? prevFooter.phone3,
          activePhone3: footer?.activePhone3 ?? prevFooter.activePhone3,
          activeFacebook: footer?.activeFacebook ?? prevFooter.activeFacebook,
          urlFacebook: footer?.urlFacebook ?? prevFooter.urlFacebook,
          activeInstagram: footer?.activeInstagram ?? prevFooter.activeInstagram,
          urlInstagram: footer?.urlInstagram ?? prevFooter.urlInstagram,
          activeTwitter: footer?.activeTwitter ?? prevFooter.activeTwitter,
          urlTwitter: footer?.urlTwitter ?? prevFooter.urlTwitter,
          activeYoutube: footer?.activeYoutube ?? prevFooter.activeYoutube,
          urlYoutube: footer?.urlYoutube ?? prevFooter.urlYoutube,
        }));
      }, [footer]);
     
       const update = e =>{
        e.preventDefault();
        let updateDataUser = updateFooter;

      
    
        updateFooterDataApi(auth.token, updateDataUser, footer?._id).then(res =>{
          
          if(res.ok === true){
            toast.success(res.message);
            setReload(true);        
       }else{
          toast.error(res.message)
       }
          })
          
        
        }
       
       
       


 useEffect(() => {
    (async () =>{
      const response = await getFooterDataApi();
      setFooter(response.footer[0]);
    })()
  }, [reload]);

 
    return (
        <div  className='container_admin_footer'>
            <div  className='container_admin_footer_nav'>
     <Container >
<h6>Datos de Contacto</h6>
<Button variant="light" onClick={update} >Actualizar</Button>
     </Container>
   </div>
   <Container fluid className='container_admin_body'>
      <Row>
          <Col md={6} sm={12} xl={4}>
              <Row className='container_data'>
              <Col md={12} sm={12} xl={12} className='title'>
              <p>Redes Sociales</p>
              </Col> 
              <Col md={12} sm={12} xl={12} className='body'>
              <Table responsive>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>URL</th>
        <th>Mostrar</th>
      </tr>
    </thead>
    <tbody>
        <tr>
            <td className='titulos'><p>Facebook</p></td>
            <td>
            <Form.Control 
            type="text" 
            placeholder="Agrega url de Facebook"
            value={updateFooter.urlFacebook}
            onChange={ e => setUpdateFooter({...updateFooter, urlFacebook: e.target.value})}
            disabled={updateFooter.activeFacebook === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activeFacebook}
      onChange={ e => setUpdateFooter({...updateFooter, activeFacebook: updateFooter.activeFacebook === !e.target.value })}
      />
            </td>
        </tr>
        <tr>
            <td className='titulos'><p>Instagram</p></td>
            <td>
            <Form.Control 
            type="text" 
            placeholder="Agrega url de Instagram"
            value={updateFooter.urlInstagram}
            onChange={ e => setUpdateFooter({...updateFooter, urlInstagram: e.target.value})}
            disabled={updateFooter.activeInstagram === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activeInstagram}
      onChange={ e => setUpdateFooter({...updateFooter, activeInstagram: updateFooter.activeInstagram === !e.target.value })}
      />
            </td>
        </tr>
        <tr>
            <td className='titulos'><p>Twitter</p></td>
            <td>
            <Form.Control 
            type="text" 
            placeholder="Agrega url de Twitter"
            value={updateFooter.urlTwitter}
            onChange={ e => setUpdateFooter({...updateFooter, urlTwitter: e.target.value})}
            disabled={updateFooter.activeTwitter === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activeTwitter}
      onChange={ e => setUpdateFooter({...updateFooter, activeTwitter: updateFooter.activeTwitter === !e.target.value })}
      />
            </td>
        </tr>
        <tr>
            <td className='titulos'><p>Youtube</p></td>
            <td>
            <Form.Control 
            type="text" 
            placeholder="Agrega url de Youtube"
            value={updateFooter.urlYoutube}
            onChange={ e => setUpdateFooter({...updateFooter, urlYoutube: e.target.value})}
            disabled={updateFooter.activeYoutube === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activeYoutube}
      onChange={ e => setUpdateFooter({...updateFooter, activeYoutube: updateFooter.activeYoutube === !e.target.value })}
      />
            </td>
        </tr>
    </tbody>
    </Table>
              </Col>   
              </Row>
              
          </Col>
          <Col md={6} sm={12} xl={4}>
              <Row className='container_data'>
              <Col md={12} sm={12} xl={12} className='title'>
              <p>Teléfonos de contacto</p>
              </Col> 
              <Col md={12} sm={12} xl={12} className='body'>
              <Table responsive>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Numero</th>
        <th>Mostrar</th>
      </tr>
    </thead>
    <tbody>
        <tr>
            <td className='titulos'><p>Teléfono 1</p></td>
            <td>
            <Form.Control 
            type="phone" 
            placeholder="Agrega un numero"
            value={updateFooter.phone1}
            onChange={ e => setUpdateFooter({...updateFooter, phone1: e.target.value})}
            disabled={updateFooter.activePhone1 === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activePhone1}
      onChange={ e => setUpdateFooter({...updateFooter, activePhone1: updateFooter.activePhone1 === !e.target.value })}
      />
            </td>
        </tr>
        <tr>
            <td className='titulos'><p>Teléfono 2</p></td>
            <td>
            <Form.Control 
            type="phone" 
            placeholder="Agrega un numero"
            value={updateFooter.phone2}
            onChange={ e => setUpdateFooter({...updateFooter, phone2: e.target.value})}
            disabled={updateFooter.activePhone2 === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activePhone2}
      onChange={ e => setUpdateFooter({...updateFooter, activePhone2: updateFooter.activePhone2 === !e.target.value })}
      />
            </td>
        </tr>
        <tr>
            <td className='titulos'><p>Teléfono 3</p></td>
            <td>
            <Form.Control 
            type="phone" 
            placeholder="Agrega un numero"
            value={updateFooter.phone3}
            onChange={ e => setUpdateFooter({...updateFooter, phone3: e.target.value})}
            disabled={updateFooter.activePhone3 === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activePhone3}
      onChange={ e => setUpdateFooter({...updateFooter, activePhone3: updateFooter.activePhone3 === !e.target.value })}
      />
            </td>
        </tr>
    </tbody>
    </Table>
              </Col>   
              </Row>
              
          </Col>
          <Col md={6} sm={12} xl={4}>
              <Row className='container_data'>
              <Col md={12} sm={12} xl={12} className='title'>
              <p>Correos electrónicos</p>
              </Col> 
              <Col md={12} sm={12} xl={12} className='body'>
              <Table responsive>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>E-mail</th>
        <th>Mostrar</th>
      </tr>
    </thead>
    <tbody>
        <tr>
            <td className='titulos'><p>Correo de bolsa de trabajo</p></td>
            <td>
            <Form.Control 
            type="e-mail" 
            placeholder="Agrega un e-mail"
            value={updateFooter.emailTrabajo}
            onChange={ e => setUpdateFooter({...updateFooter, emailTrabajo: e.target.value})}
            disabled={updateFooter.activeTrabajo === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activeTrabajo}
      onChange={ e => setUpdateFooter({...updateFooter, activeTrabajo: updateFooter.activeTrabajo === !e.target.value })}
      />
            </td>
        </tr>
        <tr>
            <td className='titulos'><p>Correo de contacto</p></td>
            <td>
            <Form.Control 
            type="e-mail" 
            placeholder="Agrega un e-mail"
            value={updateFooter.emailContacto}
            onChange={ e => setUpdateFooter({...updateFooter, emailContacto: e.target.value})}
            disabled={updateFooter.activeContacto === false ? true : false}
      /></td>
            <td>
            <Form.Check 
      type="switch" 
      checked={updateFooter.activeContacto}
      onChange={ e => setUpdateFooter({...updateFooter, activeContacto: updateFooter.activeContacto === !e.target.value })}
      />
            </td>
        </tr>
    </tbody>
    </Table>
              </Col>   
              </Row>
              
          </Col>
      </Row>
      <Row>
      <Col md={6} sm={12} xl={6}>
              <Row className='container_data'>
              <Col md={12} sm={12} xl={12} className='title'>
              <p>Agrega una dirección</p>
              <Form.Check 
      type="switch" 
      checked={updateFooter.activeDireccion}
      onChange={ e => setUpdateFooter({...updateFooter, activeDireccion: updateFooter.activeDireccion === !e.target.value })}
      />
              </Col> 
              <Col md={12} sm={12} xl={12} className='body'>
              <Form.Control as="textarea" 
              disabled={updateFooter.activeDireccion === false ? true : false}
              rows={4}
              placeholder="Escribe una dirección principal(opcional)"
              value={updateFooter.direccion}
              onChange={ e => setUpdateFooter({...updateFooter, direccion: e.target.value})}
              />
              </Col>   
              </Row>
              
          </Col>   
          <Col md={6} sm={12} xl={6}>
              <Row className='container_data'>
              <Col md={12} sm={12} xl={12} className='title'>
              <p>Agrega una descripción</p>
              <Form.Check 
      type="switch" 
      checked={updateFooter.activeDescripcion}
      onChange={ e => setUpdateFooter({...updateFooter, activeDescripcion: updateFooter.activeDescripcion === !e.target.value })}
      />
              </Col> 
              <Col md={12} sm={12} xl={12} className='body'>
              <Form.Control
               as="textarea" 
               disabled={updateFooter.activeDescripcion === false ? true : false}
               rows={4} 
               placeholder="Escribe una descripción(opcional)"
              value={updateFooter.descripcion}
              onChange={ e => setUpdateFooter({...updateFooter, descripcion: e.target.value})}
               />
              </Col>   
              </Row>
              
          </Col>  
      </Row>
   </Container>
            
        </div>
    )
}

export default Footer;