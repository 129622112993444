import React,{useState, useEffect, useCallback} from 'react';
import {Image, Spinner, Form} from 'react-bootstrap';
import {useDropzone} from 'react-dropzone';
import {toast} from 'react-toastify';
import './AddImageForm.scss';
import NoImage from '../../assets/jpg/No-Image.jpeg';

export default function AddImageForm({ setShow, setReload, token, auth, addFunction, idLugar, slide}) {
    const [imagen, setImagen] = useState(null);  
    const [loading, setLoading] = useState(false);
    const [imagenData, setImagenData] = useState({});
    const [inputs, setInputs] = useState({
      title:"",
  });
    useEffect(() => {
        setImagenData({
        imagen: imagen
        })
       }, [imagen])
       
      useEffect(() => {
        if(imagen){
          setImagenData({...imagenData,imagen: imagen.file})
        } 
      }, [imagen]);
      

      const addSlide = async e => {
        e.preventDefault();
        setLoading(true)
        let imageUpdate = imagenData;

       /*  if(typeof imageUpdate.imagen === null){
          return toast.error('Por favor agregue una imagen');
          setLoading(false);
        } */
    
        if(typeof imageUpdate.imagen === "object"){
            const res = await addFunction(imageUpdate.imagen, token, auth, idLugar?.sucursal, inputs.title);
            if(res.ok ===false){
                toast.error(res.message)
             }else{
                toast.success(res.message);
                  setShow(false);
                  setReload(true); 
             }
             setLoading(false)
        }
      }

    return(
       <>
       <Texto/>
       <AddNew
       setImagen={setImagen}
       imagen={imagen}
       />
      {
        slide ? '' :
        <Form>
        <Form.Group className="form_container">
       <Form.Control 
       type="text" 
       placeholder="Nombre o descripción de la imagen"
       name="title"
       value={inputs.title}
       onChange={e => setInputs({...inputs, title: e.target.value})}
       />
     </Form.Group> 
   </Form>
      } 
      
       <BtnFunction
       loading={loading}
       addSlide={addSlide} 
       />
       </>
    )
}

const AddNew =({setImagen, imagen}) =>{
  const onDrop = useCallback(
    acceptedFiles =>{
        const file = acceptedFiles[0];
        setImagen({file, preview: URL.createObjectURL(file) });
    }, [setImagen]
);
const {getRootProps, getInputProps, IsDragActive} = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop
});
return(
    <div className="upload-avatar" {...getRootProps()}>
     <input {...getInputProps()}/>
     {IsDragActive ? (
         <Image fluid src={NoImage} />
     ) :(
         <Image fluid  src={imagen ? imagen.preview : NoImage} />      
     )}
     
    </div>
)
}

const BtnFunction =({loading, addSlide}) =>{
  return(
    <button  disabled={loading} onClick={addSlide} id='button'>
        {
          loading === false ? '' :
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        }
        Agregar imagen
      </button>
  )
}

const Texto =() =>{
  return(
   <p id='instruccion'>Arrastre y suelte una imagen, o haga clic en la imagen</p>
  )
}