//Layout
import Layout from '../layouts/layout';
import LayoutAdmin from '../layouts/layoutAdmin';
import LayoutUser from '../layouts/layoutUser';
import LayoutServicios from '../layouts/layoutServicios';
import LayoutLugar from '../layouts/layoutLugar';
import LayoutCarrito from '../layouts/layoutCarrrito';
import LayoutPDF from '../layouts/layoutPDF';

//Paginas
import Home from '../pages/Principal';
import Personal from '../pages/Personal';
import Error404 from '../pages/Error404';
import PedidosUser from '../pages/PedidosUser';
import Carrito from '../pages/Carrito';
import LocateProfile from '../pages/LugarPerfil';
import Descripcion from '../pages/Personal';

//Servicios
import ResetPassword from '../pages/ResetPassword';
import ChangePassword from '../pages/ChangePassword';

//Administrador
import Admin from '../pages/Admin/Principal';
import Users from '../pages/Admin/Users';
import Admins from '../pages/Admin/Admins';
import Footer from '../pages/Admin/Footer';
import Lugares from '../pages/Admin/Lugares';
import Galeria from '../pages/Admin/Galeria';
import Paquetes from '../pages/Admin/Paquetes';
import Slide from '../pages/Admin/Slide';
import PedidosLugar from '../pages/Admin/PedidosForm';
import Productos from '../pages/Admin/Productos';
import UserProfile from '../pages/Admin/UserProfile';
import PackageData from '../pages/Admin/EventosUser';
import ContratoPDF from '../Components/ContratoPDF';
import EditEvent from '../pages/Admin/EditEvent';

//Servicios
import Activar from '../pages/Activar';

const routes = [{
        path: "/admin",
        component: LayoutAdmin,
        exact: false,
        routes: [{
                path: "/admin",
                component: Admin,
                exact: true
            },
            {
                path: "/admin/users",
                component: Users,
                exact: true
            }, {
                path: "/admin/admins",
                component: Admins,
                exact: true
            }, {
                path: "/admin/footer",
                component: Footer,
                exact: true
            }, {
                path: "/admin/places",
                component: Lugares,
                exact: true
            },
            {
                path: "/admin/orders",
                component: PedidosLugar,
                exact: true
            },
            {
                path: "/admin/products",
                component: Productos,
                exact: true
            },
            {
                path: "/admin/slides",
                component: Slide,
                exact: true
            },
            {
                path: "/admin/gallery/:id",
                component: Galeria,
                exact: true
            },
            {
                path: "/admin/package/:id",
                component: Paquetes,
                exact: true
            },
            {
                path: "/admin/event/:id",
                component: UserProfile,
                exact: true
            },
            {
                path: "/admin/user/:id",
                component: PackageData,
                exact: true
            },
            {
                path: "/admin/event/edit/:id",
                component: EditEvent,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/pdf",
        component: LayoutPDF,
        exact: false,
        routes: [{
                path: "/pdf/:id",
                component: ContratoPDF,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/pedidos",
        component: LayoutUser,
        exact: false,
        routes: [{
                path: "/pedidos",
                component: PedidosUser,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/activar",
        component: LayoutServicios,
        exact: false,
        routes: [{
                path: "/activar/:token",
                component: Activar,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/reset",
        component: LayoutServicios,
        exact: false,
        routes: [{
                path: "/reset",
                component: ResetPassword,
                exact: true
            }, {
                path: "/reset/:token",
                component: ChangePassword,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/carrito",
        component: LayoutCarrito,
        exact: false,
        routes: [{
                path: "/carrito",
                component: Carrito,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/:lugarname",
        component: LayoutLugar,
        exact: false,
        routes: [{
                path: "/:lugarname",
                component: LocateProfile,
                exact: true
            },
            {
                path: "/:lugarname/:id",
                component: Descripcion,
                exact: true
            },
            {
                component: Error404
            }
        ]
    },
    {
        path: "/",
        component: Layout,
        exact: false,
        routes: [{
                path: "/",
                component: Home,
                exact: true
            },
            {
                path: "/descripcion",
                component: Personal,
                exact: true
            },
            {
                component: Error404
            }
        ]
    }
];

export default routes;