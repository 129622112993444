import React from 'react';
import './Activo.scss';

const Inactivo = ({texto}) => {
    return (
        <div className='inactivo'>
           <p>{texto}</p> 
        </div>
    )
}

export default Inactivo;