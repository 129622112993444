import React,{useState, useEffect} from 'react';
import {Col, Row, Button, Form} from 'react-bootstrap';
import {getProductosApi} from '../../api/productos';
import {actualizarAdicional} from '../../api/eventos'; 
import {toast} from 'react-toastify';
import './AddEventoAdmin.scss';

export default function AddEventoAdmin({data, setReload, setShow, token, auth, reload, uid}) {
  
    const [productos, setProductos] = useState(undefined);
    const [adicionalesData, setAdicionalesData] = useState({});
   
    useEffect(() => {
      setAdicionalesData({
      nombre:data?.nombre,
      piezas:data?.piezas,
      precio:data?.precio,
      total: data?.total,
      idProducto:data?._id
      })
    }, [data]);

  useEffect(() => {
    (async () =>{
      const response = await getProductosApi();
      setProductos(response.productos);
      setReload(false)
    })()
  }, [ reload]);


  const update = async e =>{

    e.preventDefault();  
       const res = await actualizarAdicional(token, adicionalesData, uid);
       if(res.ok ===false){
        toast.error(res.message)
     }else{
        toast.success(res.message);
          setReload(true);   
          setShow(false);
     }
     e.preventDefault(); 
    
    
  }
  
  
  

  return (
    <div className='Container_form_evento_admin'>
    <Form onSubmit={update}>
      <div>
      <Row>
    <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Select
    placeholder="Selecciona un producto"
    name='nombre'
    value={adicionalesData.nombre}
    onChange={e => setAdicionalesData({...adicionalesData,nombre: e.target.value})}
    >
      <option value="">Selecciona un producto</option>
      {
        productos?.map((item) =>{
         return(
           <>
          <option  value={item.name}>{item.name}</option>
          </>
            )
                  })
      }
    </Form.Select>
    </Form.Group>
      </Col>
      <Col md={4} sm={6} xs={4} xl={4}>
      {
        (adicionalesData?.nombre  === "" || adicionalesData?.nombre  === undefined) ? 
        <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio</Form.Label>
    <Form.Control 
      type="number" 
      name="precio"
      value={adicionalesData.precio}
      onChange={e => setAdicionalesData({...adicionalesData,precio: e.target.value})}
       />
       </Form.Group>
        
        :
            productos?.filter((itemProducto) =>{
              if(itemProducto.name === adicionalesData.nombre ){
                
                
                
                return itemProducto;
                
              }
              
            }).map((itemProducto) =>{
              return(
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio</Form.Label>
    <Form.Control 
    type="text" 
    name="precio"
    value={adicionalesData.precio= itemProducto.precio}
    disabled
    onChange={e => setAdicionalesData({...adicionalesData,precio: e.target.value})}
    />
    </Form.Group>
              )}
         )
          }
    
      </Col>
      <Col md={4} sm={6} xs={4} xl={4} >
      
      <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Piezas</Form.Label>
    <Form.Control 
    type="number" 
    name="piezas"
    value={adicionalesData.piezas}
    onChange={e => setAdicionalesData({...adicionalesData,piezas: e.target.value})}
    />
    </Form.Group>
      </Col> 
   <Col md={4} sm={6} xl={4} xs={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Total</Form.Label>
    <Form.Control 
    type="number" 
    name="total"
    disabled
    value={adicionalesData.total = adicionalesData.precio  * adicionalesData.piezas} 
   /*  onChange={e => cambioData(index,e )}  */
    />
    </Form.Group>
      </Col>
      </Row>
            </div>
         <Button
        type="submit"
    >
        Actualizar
    </Button>
    </Form>   
    </div>
  )
}
