import React from 'react';
import {Container} from 'react-bootstrap';
import Image from '../../assets/png/error-404.png';
import{Link} from 'react-router-dom';
import './Error404.scss';


export default function Error404() {
    return (
        <Container className='container_error'>
            <img src={Image} alt="" />
            <h1>No se encontró esta pagina</h1>
            <Link to={"/"}>Volver a la diversión para estar seguro</Link>
        </Container>
    )
}
