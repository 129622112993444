import React,{useState, useEffect} from 'react';
import {Carousel, Container} from 'react-bootstrap';
import {GetSlidesApi} from '../../../api/slide';
import './Banner.scss';

const Banner = () => {
  const [galeria, setGaleria] = useState(null);
  
useEffect(() => {
  (async () =>{
    const response = await GetSlidesApi();
    setGaleria(response.slide);
  })()
}, []);
    return (
      <>
     <div  className='banner__container' id='Home'>
        <Container className='line_green'></Container>
        <Container className='container_data_banner'>
          <h1>¡El parque inflable más <span>grande</span> y único en el estado!</h1>
        </Container>
        <Container className='line_purple'></Container>
      </div> 
      </>
      
    );
}

export default Banner;
