import jwtDecode from 'jwt-decode';

export function setToken(token) {
    localStorage.setItem("token", token);
}

export function getToken() {
    return localStorage.getItem("token");
}

export function removeToken() {
    return localStorage.removeItem("token");
}

export function hasExpiredToken(token) {
    const tokenDecode = jwtDecode(token);
    const expireDate = tokenDecode.exp * 1000;
    const currentDate = new Date().getTime();
    if (currentDate > expireDate) {
        return true;
    } else {
        return false;
    }
}