import React from 'react';
import './NoItem.scss';

const NoItem = ({imagen, texto}) => {
    return (
        <div className='no_data_container'>
            <img src={imagen} alt="" />
         <h2>{texto}</h2> 
        </div>
    )
}

export default NoItem;