import React from 'react';
import {Container} from 'react-bootstrap';
import './Pedidos.scss';

const Pedidos = () => {
    return (
        <Container fluid className='container_pedidos'>
            <h1>Aqui va la sección de pedidos</h1>
        </Container>
    )
}

export default Pedidos;