import React, {useState, useEffect} from 'react';
import {getFooterDataApi} from '../../api/footer';
import {sendContact} from '../../api/contacto';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import Logo from '../../assets/png/logo.png';
import {ReactComponent as Facebook} from "../../assets/svg/social/facebook.svg";
import {ReactComponent as Instagram} from "../../assets/svg/social/instagram.svg";
import {ReactComponent as Twitter} from "../../assets/svg/social/twitter.svg";
import {ReactComponent as Youtube} from "../../assets/svg/social/youtube.svg";
import {ReactComponent as Email} from "../../assets/svg/mail.svg";
import {ReactComponent as Phone} from "../../assets/svg/phone.svg";
import {ReactComponent as Address} from "../../assets/svg/map-marker.svg";
import {toast} from 'react-toastify';
import './Footer.scss';
 /* pendientes: validar formulario y agregar sección de bolsa de trabajo */
export default function Footer() {
    const [loading, setLoading] = useState(false);
    const [footer, setFooter] = useState([]);
    useEffect(() => { 
        (async () =>{
          const response = await getFooterDataApi();
          setFooter(response.footer[0]);
        })()
      }, []);

    const [inputs, setInputs] = useState({
        email:"", 
        message:"",    
    });  

    const changeForm = e =>{
      setInputs({...inputs,
    [e.target.name] : e.target.value})
    }

    const register = async e =>{
      e.preventDefault();  
    
        setLoading(true)
         const res = await sendContact(inputs);
         if(res.ok ===false){
          toast.error(res.message)
       }else{
          toast.success(res.message);
       }
       setLoading(false);
       resetForm();
      
      
    }

    const resetForm = () =>{

      for(let i =0; i<inputs.length; i++){
          inputs[i].classList.remove("success");
          inputs[i].classList.remove("error");
      }

      setInputs({
      email:"",
      message:"" 
      });
  }

    return (
        <div className='footer_web' id='Contacto'>
            <Container>
                <Row>
                    <Col md={6} sm={12} xl={4} className='footer_info'>
                    <img src={Logo} alt="" />
                        <Row>
                            <Col md={12} sm={12} xl={12} className='contenido'>
                            <h5>Nosotros</h5>
                                {
                                   footer?.activeDescripcion === true &&  footer?.descripcion !== ''
                                   ?
                                   <>
                                   
                                 <p>{footer?.descripcion}</p>
                                  </>
                                  :
                                   '' 
                                 }
                             
                            </Col>
                            <Col md={12} sm={12} xl={12} className='redes_sociales'>
                                {
                                    footer?.activeFacebook === true && footer?.urlFacebook !== '' ?
                                  <a href={footer?.urlFacebook} className="content">
                                <Facebook className='face'/>
                                  </a>  
                                  :
                                  ''
                                }
                                {
                                    footer?.activeInstagram === true && footer?.urlInstagram !== '' ?
                                    <a href={footer?.urlInstagram} className="content">
                                    <Instagram className='insta'/>
                                    </a>  
                                  :
                                  ''
                                }
                                {
                                    footer?.activeTwitter === true && footer?.urlTwitter !== '' ?
                                    <a href={footer?.urlTwitter} className="content">
                                    <Twitter/>
                                   </a> 
                                  :
                                  ''
                                }
                                {
                                    footer?.activeYoutube === true && footer?.urlYoutube !== '' ?
                                    <a href={footer?.urlYoutube} className="content">
                                    <Youtube/>
                                   </a>  
                                  :
                                  ''
                                }  
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} sm={12} xl={4} className='footer_info'>
                        <Row>
                            <Col md={12} sm={12} xl={12} className='contenido'>
                             <h5>Contactanos</h5>
                             {
                                    footer?.activePhone1 === true && footer?.phone1 !== '' ?
                                    <div className="data">
                                 <Phone/>
                                 <p>{footer?.phone1}</p>
                                   </div> 
                                  :
                                  ''
                            }
                             {
                                    footer?.activePhone2 === true && footer?.phone2 !== '' ?
                                    <div className="data">
                                 <Phone/>
                                 <p>{footer?.phone2}</p>
                                   </div> 
                                  :
                                  ''
                            }
                            {
                                    footer?.activePhone3 === true && footer?.phone3 !== '' ?
                                    <div className="data">
                                 <Phone/>
                                 <p>{footer?.phone3}</p>
                                   </div> 
                                  :
                                  ''
                            }
                            {
                                    footer?.activeContacto === true && footer?.emailContacto !== '' ?
                                    <div className="data">
                                 <Email/>
                                 <p>{footer?.emailContacto}</p>
                                   </div> 
                                  :
                                  ''
                            }
                            </Col>
                            {
                                    footer?.activeDireccion === true && footer?.direccion !== '' ?
                                    <Col md={12} sm={12} xl={12} className='direccion'>
                               <Address/>
                               <p>{footer?.direccion}</p> 
                             </Col> 
                                  :
                                  ''
                            }
                            
                        </Row>
                    </Col>
                    {
                                    footer?.activeContacto === true && footer?.emailContacto !== '' ?
                    <Col md={6} sm={12} xl={4} className='footer_info'>
                      <Form onSubmit={register} onChange={changeForm}>
                        <Row>
                            <Col md={12} sm={12} xl={12} className='contenido'>
                             <h5>E-mail</h5>
                             <div className="email_form">
                             <Form.Control 
                             type="email" 
                             placeholder="Escribe un e-mail válido"
                             name='email'
                             value={inputs.email}
                            onChange={e => setInputs({...inputs, email: e.target.value})}
                             />
                             <Form.Control as="textarea" 
                             rows={4}
                             type="text"
                             placeholder="Escribe un mensaje..."
                             name='message'
                             value={inputs.message}
                            onChange={e => setInputs({...inputs, message: e.target.value})}
                             />
                             <Button variant="primary" type="submit" disabled={loading}>
                             {
                               loading === false ? '' :
                               <Spinner
                               as="span"
                               animation="border"
                               size="sm"
                               role="status"
                               aria-hidden="true"
                             />
                             }
                             Enviar Correo
                           </Button>
                             </div>
                            </Col>
                        </Row>
                        </Form>
                    </Col>
                                  :
                                  ''
                            }
                </Row>
                <Row>
                <Col md={12} sm={12} xl={12} className='footer_bottom'>
                    {/* Colocar ruta para que el año cambie en automático */}
               <p>&copy; {(new Date().getFullYear())} Inflapark </p>  
                </Col> 
                </Row>
            </Container>
        </div>
    )
}
