import React, {useState} from 'react';
import {Form, Button, Spinner} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {signUpUserApi} from '../../../api/user';
export default function Register(props) {
const [loading, setLoading] = useState(false);
    const {showLoginForm} = props;
    const formik = useFormik({
      initialValues: initialValues(),
      validationSchema: Yup.object().shape(validationSchema()),
     onSubmit:async (formData)=> {
       setLoading(true)
      const res = await signUpUserApi(formData);

      if(res.ok ===false){
      toast.error(res.message)
   }else{
      toast.success(res.message);
      showLoginForm();
   }
   setLoading(false)
     }
    })

   

    return (
        <Form className='register-form' onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control 
        type="text" 
        placeholder="Ingrese un nombre completo" 
        name="name" 
        value={formik.values.name}
        onChange={formik.handleChange}
        isInvalid={formik.errors.name && true}
        isValid={!formik.errors.name}
        />
        <Form.Control.Feedback type="invalid">
         {formik.errors.name}
        </Form.Control.Feedback>
      </Form.Group> 
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control 
        type="text" 
        placeholder="Ingrese sus apellidos" 
        name="lastname" 
        value={formik.values.lastname}
        onChange={formik.handleChange}
        isInvalid={formik.errors.lastname && true}
        isValid={!formik.errors.lastname}
        />
        <Form.Control.Feedback type="invalid">
         {formik.errors.lastname}
        </Form.Control.Feedback>
      </Form.Group>      
      <Form.Group className="mb-3" controlId="formBasicEmail" >
        <Form.Control 
        type="email" 
        placeholder="Ingrese un correo electrónico" 
        name="email" 
        value={formik.values.email}
        onChange={formik.handleChange}
        isInvalid={formik.errors.email && true}
        isValid={!formik.errors.email}
        />
        <Form.Control.Feedback type="invalid">
         {formik.errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail" >
        <Form.Control 
        type="phone" 
        placeholder="Ingrese un número telefónico" 
        name="phone" 
        value={formik.values.phone}
        onChange={formik.handleChange}
        isInvalid={formik.errors.phone && true}
        isValid={!formik.errors.phone}
        />
        <Form.Control.Feedback type="invalid">
         {formik.errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword" >
        <Form.Control 
        type="password" 
        placeholder="Password" 
        name="password" 
        onChange={formik.handleChange}
        isInvalid={formik.errors.password && true}
        isValid={!formik.errors.password }
        />
         <Form.Control.Feedback type="invalid">
         {formik.errors.password}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword" >
        <Form.Control 
        type="password" 
        placeholder="Repetir password"  
        name="repeatPassword" 
        onChange={formik.handleChange}
        isInvalid={formik.errors.repeatPassword && true}
        isValid={!formik.errors.repeatPassword }
        />
        <Form.Control.Feedback type="invalid">
         {formik.errors.repeatPassword}
        </Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit" disabled={loading}>
        {
          loading === false ? '' :
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        }
        Crear cuenta
      </Button>
      <div className="opciones">
      <p className='cambio' onClick={showLoginForm}>Iniciar sesión </p> 
      </div>
    </Form>
    )
}

function initialValues(){
  return {
    name:"",
    email:"",
    password:"",
    repeatPassword:"",
    phone:"",
    lastname:""
  }
}
function validationSchema(){
  return{
    name: Yup.string().required('Ingrese su nombre(s)', true),
    lastname: Yup.string().required('Ingrese sus apellidos', true),
    email: Yup.string().email("El email no es valido").required('Email es obligatorio', true),
    phone: Yup.string().required('Número telefónico es obligatorio', true).min(10, 'El número debe contener un minimo de 10 caracteres'),
    password: Yup.string().required('La contraseña es obligatoria',true).oneOf([Yup.ref('repeatPassword')], 'Las contraseñas no son iguales').min(6, 'La contraseña debe contener un minimo de 6 caracteres'),
    repeatPassword: Yup.string().required('La contraseña es obligatoria',true).oneOf([Yup.ref('password')], 'Las contraseñas no son iguales').min(6, 'La contraseña debe contener un minimo de 6 caracteres'),
  };
}
