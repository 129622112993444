import React, {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Container, Table} from 'react-bootstrap';
import useAuth from '../../../hooks/useAuth';
import Modal from '../../../Components/ModalNormal';
import ModalLG from '../../../Components/ModalLg';
import DeleteUserData from '../../../Components/DeleteUser';
import AddAbono from '../../../Components/AddAbono';
import EditEventoForm from '../../../Components/EditEventoForm';
import {getEventosUser, deleteEventoApi} from '../../../api/eventos';  
import {getLUserDataApi} from '../../../api/user'; 
import {ReactComponent as Pay} from "../../../assets/svg/admin/money-bill.svg";
import {ReactComponent as PfdIcon} from "../../../assets/svg/eye-solid.svg";
import {ReactComponent as Delete} from "../../../assets/svg/admin/trash.svg";
import {ReactComponent as Edit} from "../../../assets/svg/admin/edit-solid.svg";
import NoImage from '../../../assets/png/party.png'
import NoItem from '../../../Components/NoItem';
import moment from 'moment';
import 'moment/locale/es-mx'
import './EventosUser.scss';

export default function EventosUser() {
    const params = useParams();
    const {auth} = useAuth();
    const [reload, setReload] = useState(false);
    const [eventos, setEventos] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [show, setShow] = useState(false);
    const [showLG, setShowLG] = useState(false);
    const [modalTitle, setModalTitle] = useState(""); 
    const [modalContent, setModalContent] = useState(null);
 
    const date1 = "YYYY-MM-DD"
  
    //Llama todos los usuario   
useEffect(() => {
    (async () =>{
      const response = await getEventosUser(auth?.token, params.id);
      setEventos(response.eventoData);
      setReload(false)
    })()
  }, [auth, reload]);

  //llama datos del usuario autenticado
useEffect(() => {
    (async () =>{
      const response = await getLUserDataApi(auth.token, auth.id);
      setUser(response.userData);
    })()
  }, [auth]);
  return (
    <div className='eventos_user_container'>  
    <div fluid className='users_section_nav'>
     <Container >
<h5>Eventos</h5>
     </Container>
   </div>
   <Container fluid className="users_section_data">
   {eventos === undefined || eventos === '' || eventos?.length === 0 ?
    <NoItem imagen={NoImage} texto={'Aun no tienes eventos'}/> 
    :
    <Table responsive>
    <thead>
      <tr>
        <th>Evento</th>
        <th>Paquete</th>
        <th>Fecha</th>
        <th>Abonado</th>
        <th>Adeudo</th>
        <th>Total</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
          {
            eventos?.map((item =>{
              return(
              <tr key={item.uid}>
              <td>{item.name ?? ''}</td>
              <td><p>{item.paquete ?? ''}</p></td>
              <td>{
                moment(item?.fecha).format(date1) ?? ''}</td>
              <td><Abonos abonos={item.abonos}/></td>
              <td><Adeudo abonos={item.abonos} total={item.precioTotal}/></td>
              <td className='total'>${item.precioTotal ?
              Intl.NumberFormat('es-MX').format(item.precioTotal)
               : 0}</td>
              <td className='options_user_admin'>
                <div className="pay">
                 <AddPay
                 data={item} 
                 setShow={setShow} 
                 setModalTitle={setModalTitle} 
                 setModalContent={setModalContent} 
                 setReload={setReload}
                 token={auth.token}
                 />
                </div>
                <div className="editar">
                {/* <EditEvent 
                  data={item} 
                  setShow={setShowLG} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  /> */}
                  <Link to={`/admin/event/edit/${item.uid}`}>
                  <Edit/>
                <p>Editar</p>
                  </Link>
                  </div>
                <div className="pdf">
                  <Link 
                to={`/pdf/${item.uid}`}
                target="_blank">
                  <PfdIcon/>
                <p>Ver</p>
                  </Link>
                 </div>
                 {
                    user?.role === 'super_admin' ?
                 <div className="delete">
                <DeleteEvent 
                  data={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  deleteFunction={deleteEventoApi}
                  />
                  </div>
                : ''  
                }
                 </td>
              </tr>
            )}))
          }
        </tbody>
    </Table>
    }
   </Container>
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    /> 
    <ModalLG
    show={showLG}
    setShow={setShowLG}
    title={modalTitle}
    body={modalContent}
    /> 
    </div>
  )
}

const AddPay = ({data, setShow, setReload, token,  setModalTitle, setModalContent}) => {

    const editUser = data =>{
        setShow(true);
        setModalTitle("Agregar Abono")
        setModalContent(<AddAbono  
            token={token} 
            idEvento={data?.uid} 
            setReload={setReload}
            setShow={setShow}
            data={data}
          />)
      }
    return (
      <>
      <Pay onClick={()=>editUser(data)}/>
      <p>Abonar</p>
      </>
      
    );
  }

  const DeleteEvent = ({data, setShow, setReload, token,  setModalTitle, setModalContent, deleteFunction}) => {
  
    const editUser = data =>{
      setShow(true);
      setModalTitle("")
      setModalContent(<DeleteUserData 
        id={data?.uid} 
        setReload={setReload} 
        setShow={setShow} 
        token={token} 
        titulo={`${data.name}?`}
        deleteFunction={deleteFunction}  
        />)
    }
    return (
      <>
      <Delete onClick={()=>editUser(data)}/>
      <p>Eliminar</p>
      </>
    );
  }

  const EditEvent = ({data, setShow, setReload, token,  setModalTitle, setModalContent, deleteFunction}) => {
  
    const editUser = data =>{
      setShow(true);
      setModalTitle(`Editando ${data?.name}`)
      setModalContent(<EditEventoForm  
          token={token} 
          idEvento={data?.uid} 
          setReload={setReload}
          setShow={setShow}
          data={data}
        />)
    }
    return (
      <>
      <Edit onClick={()=>editUser(data)}/>
      <p>Editar</p>
      </>
    );
  }
  
  const Abonos = ({ abonos }) => {
    // Filtrar el array abonos para obtener solo los elementos con un campo abono
    const abonosConAbono = abonos.filter((abono) => abono.abono !== undefined);
  
    // Calcular la suma de los abonos
    const sumaAbonos = abonosConAbono.reduce(
      (prev, next) => prev + parseFloat(next.abono),
      0
    );
  
    return (
      <span className='abonos'>
        ${Intl.NumberFormat('es-MX').format(sumaAbonos)}
      </span>
    );
  };



  const Adeudo = ({ abonos, total }) => {
    // Filtrar el array abonos para obtener solo los elementos con un campo abono
    const abonosConAbono = abonos.filter((abono) => abono.abono !== undefined);
  
    // Calcular la suma de los abonos
    const sumaAbonos = abonosConAbono.reduce(
      (prev, next) => prev + parseFloat(next.abono),
      0
    );
  
    // Calcular el adeudo restando los abonos del total
    const adeudo = total - sumaAbonos;
  
    return (
      <span className='adeudo'>
        ${Intl.NumberFormat('es-MX').format(adeudo)}
      </span>
    );
  };

 