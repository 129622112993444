import React, {useState, useEffect }from 'react';
import {Container, Table, Form} from 'react-bootstrap';
import Activo from '../../../Components/Activo';
import Inactivo from '../../../Components/Inactivo';
import {Link} from 'react-router-dom'
import Modal from '../../../Components/ModalNormal';
import {ReactComponent as Edit} from "../../../assets/svg/admin/user-edit.svg";
import {ReactComponent as Delete} from "../../../assets/svg/admin/trash.svg";
import {ReactComponent as Evento} from "../../../assets/svg/admin/calendar-plus.svg";
import {ReactComponent as Pastel} from "../../../assets/svg/admin/cake.svg";
import EditUserForm from '../../../Components/EditUserForm';
import DeleteUserData from '../../../Components/DeleteUser';
import './Users.scss';
import useAuth from '../../../hooks/useAuth';
import {getUsers, getLUserDataApi, deleteUsuarioApi} from '../../../api/user'; 

export default function Users() {
  const [user, setUser] = useState(undefined);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(""); 
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const {auth} = useAuth();
  const [usersData, setUsersData] = useState(undefined);
 
//Llama todos los usuario  
useEffect(() => {
    (async () =>{
      const response = await getUsers(auth.token);
      setUsersData(response.users);
      setReload(false)
    })()
  }, [auth, reload]);

//llama datos del usuario autenticado
useEffect(() => {
  (async () =>{
    const response = await getLUserDataApi(auth.token, auth.id);
    setUser(response.userData);
  })()
}, [auth]);
  return (
  <div fluid className='users_section'>
   <div fluid className='users_section_nav'>
     <Container >
<h5>Usuarios</h5>
   <div className="search_bar">
   <Form.Control type="text" 
   placeholder="Buscar..." 
   onChange={(e)=>{
    setSearch(e.target.value);
  }}
   />
   </div>
     </Container>
   </div>
   <Container fluid className="users_section_data">
    <Table responsive>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Apellido</th>
        <th>E-mail</th>
        <th>Teléfono</th>
        <th>Activo</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
          {
            usersData?.filter(item =>{
              if(item.role ==="user" ){
                if(search ===''){
                return item;
              }else if(
                (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
                (item.lastname && item.lastname.toLowerCase().includes(search.toLowerCase())) ||
                (item.email && item.email.toLowerCase().includes(search.toLowerCase()))
              ){
             
                return item
              }
              }
              
            }).map((item =>{
              return(
              <tr key={item.uid}>
              <td className='options_user_name'>{item.name ?? ''}</td>
              <td className='options_user_name'>{item.lastname ?? ''}</td>
              <td className='options_user_name'>{item.email ?? ''}</td>
              <td className='options_user_name'>{item.phone ?? ''}</td>
              <td>{item.active == true ? <Activo texto={'Activo'}/> : <Inactivo texto={'Inactivo'}/>}</td>
              <td className='options_user_admin'>
                <div className="edit">
                  <EditUser 
                  userdata={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  auth={auth.id}
                  />
                </div>
                {
                    user?.role === 'super_admin' ?
                    <div className="delete">
                <DeleteUser 
                  userdata={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  deleteFunction={deleteUsuarioApi}
                  />
                  </div>
                  : ''
                }
                
                <div className="profile">
                  <Link to={`/admin/user/${item.uid}`}>
                  <Pastel/>
                <p>Eventos</p>
                  </Link>
                 </div>
                 <div className="evento">
                  <Link to={`/admin/event/${item.uid}`}>
                  <Evento/>
                <p>Agendar</p>
                  </Link>
                 </div>
                 </td>
              </tr>
            )}))
          }
        </tbody>
    </Table>
   </Container>
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    /> 
  </div>
  );
}


const EditUser = ({userdata, setShow, setModalTitle, setModalContent, setReload, token, auth}) => {
  
  const editUser = userdata =>{
    setShow(true);
    setModalTitle(`Editanto a ${userdata.name}`)
    setModalContent(<EditUserForm userdata={userdata} setReload={setReload} setShow={setShow} token={token} auth={auth}/>)
  }
  return (
    <>
    <Edit onClick={()=>editUser(userdata)}/>
    <p>Editar</p>
    </>
    
  );
}

const DeleteUser = ({userdata, setShow, setReload, token,  setModalTitle, setModalContent, deleteFunction}) => {
  
  const editUser = userdata =>{
    setShow(true);
    setModalTitle("")
    setModalContent(<DeleteUserData 
      id={userdata?.uid} 
      setReload={setReload} 
      setShow={setShow} 
      token={token} 
      titulo={`${userdata.name} ${userdata.lastname}?`}
      deleteFunction={deleteFunction}  
      />)
  }
  return (
    <>
    <Delete onClick={()=>editUser(userdata)}/>
    <p>Eliminar</p>
    </>
  );
}



