import React,{useState} from 'react';
import {Form, Button, Spinner} from 'react-bootstrap';
import {useFormik} from 'formik';
import {Link} from 'react-router-dom';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {signInUserApi} from '../../../api/user';
import useAuth from '../../../hooks/useAuth';
import './Login.scss';

export default function Login(props) {
  const [loading, setLoading] = useState(false);
  const {login} = useAuth();
  const resetPassword = ()=>{
    
  }

  const {showRegisterForm, setShow} =props;
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape(validationSchema()),
   onSubmit:async (formData)=> {
     setLoading(true)
    const res = await signInUserApi(formData);

    if(res.ok === true){
      login(res?.result.token)
      toast.success(res.message);
      setShow(false);
 }else{
    toast.error(res.message)
 }
 setLoading(false)
   }
  })
    return (
      <Form className='register-form' onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail" >
        <Form.Control 
        type="email" 
        placeholder="Ingrese un correo electrónico" 
        name="email" 
        value={formik.values.email}
        onChange={formik.handleChange}
        isInvalid={formik.errors.email && true}
        isValid={!formik.errors.email}
        />
        <Form.Control.Feedback type="invalid">
         {formik.errors.email}
        </Form.Control.Feedback>
      </Form.Group>
    
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control 
        type="password" 
        placeholder="Password" 
        name="password" 
        value={formik.values.password}
        onChange={formik.handleChange}
        isInvalid={formik.errors.password && true}
        isValid={!formik.errors.password }
        />
         <Form.Control.Feedback type="invalid">
         {formik.errors.password}
        </Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit" disabled={loading}>
        {
          loading === false ? '' :
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        }
        Iniciar sesión
      </Button>
      <div className="opciones">
      <p className='cambio' onClick={showRegisterForm} >Crear cuenta</p> <p> | </p>
      <Link to={'/reset'}>Olvide mi contraseña</Link>
      </div>
    </Form>
    )
}

function initialValues(){
  return {
    email:"",
    password:""
  }
}
function validationSchema(){
  return{
    email: Yup.string().email("El email no es valido").required('Email es obligatorio', true),
    password: Yup.string().required('La contraseña es obligatoria',true).min(6, 'La contraseña debe contener un minimo de 6 caracteres'),
  };
}
