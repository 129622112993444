//Crea un usuario
export function signUpUserApi(data) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.user) {
                return { ok: true, message: '¡Cuenta registrada! Por favor revise su correo electrónico para el enlace de activación.' };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Login de usuario
export function signInUserApi(data) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/auth`;

    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json"
        }
    }

    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.user) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Obtener datos del usuario
export function getLUserDataApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

//Obtener usuarios
export function getUsers(token) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

//Activa las cuentas del usuario
export function activarCuenta(token, status) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios/activarusuario/${token}`;

    const params = {
        method: "PUT",
        body: JSON.stringify({ active: status }),
        headers: {
            "content-Type": "application/json",
            "x-auth-token": token
        }
    }

    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.msg;
        })

}

//Actualiza los datos de la usuario
export function updateUsuarioApi(token, usuario, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(usuario)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.user) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Elimina a una usuario
export function deleteUsuarioApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios/${id}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.user) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Crea un usuario
export function resetPassword(data) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios/verificaremail`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.token) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

export function resetPasswordLocate(token, data) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/usuarios/cambiarPassword/usuario/${token}`;

    const params = {
        method: "PUT",
        body: JSON.stringify({ password: data }),
        headers: {
            "content-Type": "application/json"
        }
    }

    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.token) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}