import React, {useState, useEffect} from 'react';
import {Form, Button, Col, Row} from 'react-bootstrap';
import './EditUserForm.scss';
import {updateLocatesDataApi, getLugarDataApi} from '../../api/lugares';
import {toast} from 'react-toastify';

//Crear formulario de edición de usuario y también crear ruta, para actualizar información del usuario
function EditUserForm({userdata ,setReload,setShow, token, auth}) {
   

  const [user, setUser] = useState(undefined);
  const [updateUser, setUpdateUser] = useState({});
  useEffect(() => {
    setUpdateUser({
     sucursal:userdata.sucursal,
     lugarname:userdata.lugarname,
     phone: userdata.phone,
     email:userdata.email,
     horario:userdata.horario,
     direccion:userdata.direccion,
     mapaURL:userdata.mapaURL,
     whatsApp:userdata.whatsApp
    })
   }, [userdata])

   useEffect(() => {
    (async () =>{
      const response = await getLugarDataApi(token, userdata.uid);
      setUser(response.lugarData);
    })()
  }, [auth]);


     /* Se actualizan los datos del puesto */
  const update = e =>{
    e.preventDefault();
    let updateDataUser = updateUser;
/* 
    if(!updateDataUser.phone.length !==10){
      toast.error("Número de teléfono debe de contener 10 dígitos.")
      return;
    } */

    if(!updateDataUser.lugarname){
      toast.error("url del llugar es obligatoria.")
      return;
    }
    
  else{
    updateLocatesDataApi(token, updateDataUser, user?.uid).then(res =>{
      
      if(res.ok === true){
        toast.success(res.message);
        setShow(false);
        setReload(true);        
   }else{
      toast.error(res.message)
   }
      })
      
    }
    }
  return (
    <Form onSubmit={update}>
      <Row>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Sucursal"
      value={updateUser.sucursal}
      onChange={ e => setUpdateUser({...updateUser, sucursal: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="url lugar"
      value={updateUser.lugarname}
      onChange={ e => setUpdateUser({...updateUser, lugarname: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="phone" 
      placeholder="Teléfono (10) digitos"
      value={updateUser.phone}
      onChange={ e => setUpdateUser({...updateUser, phone: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="emali" 
      placeholder="E-mail"
      value={updateUser.email}
      onChange={ e => setUpdateUser({...updateUser, email: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="whatsApp" 
      placeholder="WhatsApp"
      value={updateUser.whatsApp}
      onChange={ e => setUpdateUser({...updateUser, whatsApp: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={6} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Horario"
      value={updateUser.horario}
      onChange={ e => setUpdateUser({...updateUser, horario: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Agregue una URL de mapa de google"
      value={updateUser.mapaURL}
      onChange={ e => setUpdateUser({...updateUser, mapaURL: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
        <Form.Group  controlId="ControlTextarea1">
        <Form.Control 
         as="textarea"
         rows={3}
         placeholder="Dirección"
         value={updateUser.direccion}
         onChange={ e => setUpdateUser({...updateUser, direccion: e.target.value})}
         />
        </Form.Group>
        </Col>
      </Row>
    <Button variant="primary" type="submit">
      Actualizar
    </Button>
  </Form>
  );
}

export default EditUserForm;
