import React,{useState, useEffect} from 'react';
import {Spinner, Col, Row, Button, Form} from 'react-bootstrap';
import {getProductosApi} from '../../api/productos';
import {addAdicional} from '../../api/eventos';
import {toast} from 'react-toastify';
import './AddEventoAdmin.scss';

export default function AddEventoAdmin({setReload, setShow, token, auth, reload}) {
    const [productos, setProductos] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
      nombre:"", 
      precio:"",  
      piezas:1,
      total:"",   
  });
   const [inputSelect, setInputSelect] = useState("0");
   const [inputColorText, setinputColorText] = useState("0");
  useEffect(() => { 
    setInputSelect({
       color:inputSelect,
    })
   }, [])
  
   useEffect(() => { 
    setinputColorText({
      colorTexto:inputColorText,  
    })
   }, [])
  
  const changeForm = e =>{
    setInputs({...inputs,
  [e.target.name] : e.target.value})
  }
  
  const register = async e =>{
    
    const dataNew = {
      nombre:inputs.nombre,
      piezas:inputs.piezas,
      precio:inputs.precio,
      total:inputs.total
    }

    const dataAbonos = {
      adicionales:dataNew,
    }
    e.preventDefault();  
  
      setLoading(true)
       const res = await addAdicional(token, dataAbonos, auth);
       if(res.ok ===false){
        toast.error(res.message)
     }else{
        toast.success(res.message);
          setReload(true);   
          setShow(false);
     }
     setLoading(false);
    
    
  }
  useEffect(() => {
    (async () =>{
      const response = await getProductosApi();
      setProductos(response.productos);
     /*  setReload(false) */
    })()
  }, [ reload]);
  
  
  

  return (
    <div className='Container_form_evento_admin'>
    <Form onSubmit={register} onChange={changeForm}>
      <div>
      <Row>
    <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Select
    placeholder="Selecciona un producto"
    name='nombre'
    value={inputs.nombre}
    onChange={e => setInputs({...inputs,nombre: e.target.value})}
    >
      <option value="">Selecciona un producto</option>
      {
        productos?.map((item) =>{
         return(
           <>
          <option  value={item.name}>{item.name}</option>
          </>
            )
                  })
      }
    </Form.Select>
    </Form.Group>
      </Col>
      <Col md={4} sm={6} xs={4} xl={4}>
      {
        (inputs?.nombre  === "" || inputs?.nombre  === undefined) ? 
        <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio</Form.Label>
    <Form.Control 
      type="number" 
      name="precio"
      value={inputs.precio}
      onChange={e => setInputs({...inputs,precio: e.target.value})}
      disabled
       />
       </Form.Group>
        
        :
            productos?.filter((itemProducto) =>{
              if(itemProducto.name === inputs.nombre ){
                
                
                
                return itemProducto;
                
              }
              
            }).map((itemProducto) =>{
              return(
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio</Form.Label>
    <Form.Control 
    type="text" 
    name="precio"
    value={inputs.precio= itemProducto.precio}
    disabled
    onChange={e => setInputs({...inputs,precio: e.target.value})}
    />
    </Form.Group>
              )}
         )
          }
    
      </Col>
      <Col md={4} sm={6} xs={4} xl={4} >
      
      <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Piezas</Form.Label>
    <Form.Control 
    type="number" 
    name="piezas"
    value={inputs.piezas}
    onChange={e => setInputs({...inputs,piezas: e.target.value})}
    />
    </Form.Group>
      </Col>
   <Col md={4} sm={6} xl={4} xs={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Total</Form.Label>
    <Form.Control 
    type="number" 
    name="total"
    disabled
    value={inputs.total = inputs.precio  * inputs.piezas}
    onChange={e => setInputs({...inputs,total: e.target.value})}
    />
    </Form.Group>
      </Col>
      </Row>
            </div>
            <Button type="submit" disabled={loading}>
      {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      Agregar 
    </Button>
    </Form>   
    </div>
  )
}
