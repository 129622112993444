import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import { Link as LinkRouter} from 'react-router-dom';
import Logo from '../../assets/png/logo.png';
import Modal from '../ModalNormal';
import Auth from '../Auth';
import useAuth from '../../hooks/useAuth'; 
import {getLUserDataApi} from '../../api/user';
import {ReactComponent as Close} from "../../assets/svg/times-solid.svg";
import {ReactComponent as Open} from "../../assets/svg/bars-solid.svg";
import { Link } from "react-scroll";
 
import './Navbar.scss';


export default function Navbar() { 
  const [active, setActive] = useState("navBar");
  const [button, setButton] = useState(false);
  const [data, setData] = useState("opciones_user");
  const [show, setShow] = useState(false); 
  const [user, setUser] = useState(undefined);
  const {logout, auth} = useAuth();
  
  useEffect(() => {
    if (auth) { // Verifica si auth no es undefined
      (async () => {
        const response = await getLUserDataApi(auth.token, auth.id);
        setUser(response.userData);
      })();
    }
  }, [auth]);
  
   
 


  const [titleModal, setTitleModal] = useState("Inicia sesión");
  const navToggle = () =>{
    active === 'navBar' ? setActive('navBar nav__active') : setActive('navBar')
    button === false ? setButton(true) : setButton(false)
  }
  const dataUserMenu = () =>{
    data === 'opciones_user' ? setData('opciones_user mostrar') : setData('opciones_user') 
  }
  
  const cerrarSesion = () =>{
    logout();
    setData('opciones_user');
    
  }

    return (
      <>
        <Container className='navbar_top' fluid>
          <Container>
            <div className="menu-bar" id="menu-bar" onClick={navToggle}>
             {button === false ? <Open/> : <Close/>}
            </div>
          <Link className="logo" to={"/"} >
  <img src={Logo} alt="" />
         </Link>
         <div className="nav_bottom">
<nav className={active}>
         <Link  className="nav__link"
                activeClass="active"
                to="Home"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Home</Link>
                <Link  className="nav__link"
                activeClass="active"
                to="Conocenos"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Conocenos</Link>
                <Link  className="nav__link"
                activeClass="active"
                to="Promociones"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Promociones</Link>
                <Link  className="nav__link"
                activeClass="active"
                to="Sucursales"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Sucursales</Link>
                <Link  className="nav__link"
                activeClass="active"
                to="Contacto"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Contacto</Link>
         </nav>
         <div className="icons">
           {(auth && user?.role ==="user" )? (
             <>
             <a  className='cuenta' onClick={dataUserMenu}>Tu cuenta</a>
             <div className={data}>
              <li><LinkRouter to={"/pedidos"}>Mis pedidos</LinkRouter></li>
              <li><a onClick={cerrarSesion}>Cerrar sesión</a></li>
             </div>
             </>
           ):
           (auth && user?.role ==="admin" || auth && user?.role ==="super_admin") ?
           <>
           <a  className='cuenta' onClick={dataUserMenu}>Administrador</a>
           <div className={data}>
            <li><LinkRouter to={"/admin"}>Ir a Admin</LinkRouter></li>
            <li><a onClick={cerrarSesion}>Cerrar sesión</a></li>
           </div>
           </>
           :
          (  <a  className='cuenta' onClick={()=> setShow(true)}>Inicia Sesión</a>
          
         /*  <User className='user' onClick={()=> setShow(true)} /> */)
          } 
         </div>
         </div>
          </Container>
         <div className="line"></div>
        </Container >
        <Modal
        show={show}
        setShow={setShow}
        title={titleModal}
        body={<Auth
        setTitleModal={setTitleModal}
        setShow={setShow}
        />}
        />
        </>
    )
}
